header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #FFF;
  width: 100%;
  height: 100px;
}

header .navbar-brand {
  font-family: Catamaran;
  font-size: 24px;
  font-weight: 900;
  color: #111;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 15px 0 10px 0;
}

header nav ul {
  display: block;
  margin: 0 auto;
  width: fit-content;
}

header nav ul li {
  float: left;
  list-style: none;
  padding: 0 16px;
}

header nav ul li .navbar-link {
  font-family: Catamaran;
  font-size: 16px;
  color: #111;
  text-transform: uppercase;
}

header .navbar-cases {
  display: none;
}

@media only screen and (min-width: 1000px) {
  header .navbar-brand {
      margin: 31px 0;
      font-size: 31px;
      text-align: left;
      line-height: 38px;
      padding: 0px 20px 0px 40px;
      border-right: 3px solid #111;
      float: left;
  }

  header nav ul {
      margin: 20px 0 0 20px;
      float: left;
  }
  
  header nav ul li .navbar-link {
      font-size: 20px;
      line-height: 60px;
  }

  header .navbar-cases {
      display: block;
      font-family: Catamaran;
      font-size: 20px;
      color: #111;
      text-transform: uppercase;
      line-height: 38px;
      border: 1px solid #111;
      float: right;
      margin-right: 40px;
      margin-top: 30px;
      padding: 3px 20px 2px 20px;
  }
}