.hero-container {
    width: 100%;
    height: calc(100vh - 100px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: table;
}

.vertical-center {
    display: table-cell;
    vertical-align: middle;
}

.hero-home {
    background-image: url("../../assets/img/hero-home.png");
}

.hero-about {
    background-image: url("../../assets/img/hero-about.png");
}

.hero-contact {
    background-image: url("../../assets/img/hero-contact.png");
}

.hero-container h2 {
    font-family: Catamaran;
    font-size: 55px;
    font-weight: 900;
    line-height: 70px;
    color: #FFF;
    text-align: center;
    text-shadow: 2px 2px 8px #111;
}

.hero-container h1 {
    font-family: Cormorant Garamond;
    font-size: 28px;
    font-weight: 100;
    font-style: italic;
    line-height: 34px;
    color: #FFF;
    text-align: center;
    text-shadow: 2px 2px 8px #111;
}

@media only screen and (min-width: 960px) {
    .hero-container {
        height: 500px;
        margin: 0 auto;
    }

    .hero-container h1{
        display: block;
        width: 600px;
        margin: 0 auto;
    }

    .hero-home {
        background-position: center top;
    }
}