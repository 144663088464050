.contact-form-section {
    margin-top: 26px;
}

.contact-form-section .contact-form-container {
    background-color: #f2f2f2;
    margin: 16px 16px 0;
    overflow: hidden;
}

.contact-form-section .contact-form-container h2 {
    font-family: Catamaran;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-left: 15px;
    padding-top: 15px;
    color: #111;
    text-transform: uppercase;
}

@media only screen and (min-width: 1000px) {
    .contact-form-section .contact-form-container {
        margin: 0;
        width: 1000px;
        background-color: transparent;
    }

    .contact-form-section .contact-form-container h2 {
        font-size: 35px;
        text-align: left;
    }
}