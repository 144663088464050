.projects-section {
    margin: 15px 15px 100px 15px;
    overflow: hidden;
}

.projects-section h2 {
    font-family: "Roboto Mono";
    font-size: 24px;
    margin: 0 0 15px 15px;
    padding-top: 15px;
    text-transform: uppercase;
}

@media only screen and (min-width: 1000px) {
    .projects-section h2 {
        font-size: 35px;
        padding-top: 30px;
    }
}