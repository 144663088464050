.plain-link:visited {
    text-decoration: none;
    color: #111;
}

.boxlink {
    margin: 15px auto;
    width: calc(100% - 60px);
    background-color: transparent;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center 15%;
    background-size: cover;
    display: table;
    color: #FFF;
    box-shadow: 0 0 2px 2px #111 inset;
    transition-duration: 0.2s;
}

.boxlink p {
    padding: 35px 0;
}


.boxlink:hover {
    box-shadow: 0 0 20px 20px #111 inset;
    transition-duration: 0.4s;
}

@media only screen and (min-width: 1000px) {
    .boxlink {
        margin: 15px 15px;
        overflow: hidden;
        float: left;
    }
    
    .boxlink .vertical-spacer {
        display: table-cell;
        vertical-align: middle;
    }

    .card-item--large {
        width: 293px;
        height: 280px;
    }

    .card-item--medium {
        width: 220px;
        height: 220px;
        margin: 20px 10px 0;
    }

    .card-item--medium p {
        top: 25% !important;
    }

    .boxlink p {
        position: relative;
        top: 30%;
        font-size: 25px;
    }
}