* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Catamaran;
  text-decoration: none;
}

main {
  padding-top: 100px;
}

nav ul {
  list-style:none;
}

@media only screen and (min-width: 1000px) {
  .wrapper {
    width: 1000px;
    margin: 0 auto !important;
  }
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}