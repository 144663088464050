.section {
    margin: 15px 15px 0;
    background-color: #f2f2f2;
    display: table;
    overflow: hidden;
}

.section img {
    width: calc(100% - 30px);
    float: right;
    margin: 0 15px 15px 0;
}

.section .infobox {
    display: block;
    float: left;
    width: 100%;
}

.section .infobox h2 {
    font-family: "Roboto Mono";
    font-size: 24px;
    margin: 0 0 15px 15px;
    padding-top: 15px;
    text-transform: uppercase;
}

.section .infobox p {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 15px 15px;
}


@media only screen and (min-width: 1000px) {
    .section {
        overflow: hidden;
    }
    
    .section img {
        width: 200px;
        margin: 6% 15px 15px 15px;
    }
    
    .section .infobox {
        width: 710px;
    }
    
    .section .infobox h2 {
        font-size: 38px;
    }
    
    .section .infobox p {
        font-size: 22px;
        line-height: 30px;
    }

    .section .imagebox {
        display: table-cell;
        vertical-align: middle;
    }
}