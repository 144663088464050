.btn {
  margin: 25px 15px 0;
  padding: 20px 0;
  background-color: #111;
  width: calc(100% - 30px);
  text-align: center;
}

.btn p {
  color: #fff;
  font-size: 20px;
  font-family: "Roboto Mono";
  font-weight: 600;
}

@media only screen and (min-width: 1000px) {
  .btn {
    margin: 40px 15px 0;
    padding: 30px 0;
  }

  .btn p {
    font-size: 30px;
  }
}