.hero-case-f2b {
    background-image: url("../../assets/img/f2bImg.PNG");
}

.hero-case-mi {
    background-image: url("../../assets/img/miImg.PNG");
}

.hero-case-ib {
    background-image: url("../../assets/img/ibImg.PNG");
}

.hero-case-clndirv2 {
    background-image: url("../../assets/img/ibImg.PNG");
}

.hero-case-ad-ou {
    background-image: url("../../assets/img/activeDirectoryImg.PNG");
}

.hero-case-clndirv2 {
    background-image: url("../../assets/img/cleanDirImg.PNG");
}

.hero-case-hsc {
    background-image: url("../../assets/img/hscImg.PNG");
}

.hero-case-dv {
    background-image: url("../../assets/img/dvImg.png");
}