footer {
    width: 100%;
    padding: 40px 40px;
    margin-top: 20px;
    background-color: #111;
    overflow:hidden;
}

footer ul {
    width: fit-content;
    float: left;
    padding-left: 20px;
}

footer ul li {
    display: block;
    list-style: none;
}

footer ul li a {
    font-family: Catamaran;
    font-size: 24px;
    color: #fff;
    line-height: 40px;
}

.footer-links-cases {
    display: none;
}

.footer-sm {
    width: 50px;
    float: right;
}

.footer-sm img {
    width: 100%;
    margin-bottom: 10px;
}

@media only screen and (min-width: 1000px) {
    .wrapper {
        width: 1000px;
        margin: 0 auto;
    }

    .footer-links-cases {
        display: block;
    }

    footer ul {
        padding-right: 30px;
    }

    footer ul li p {
        font-family: Catamaran;
        font-size: 24px;
        color: #fff;
        line-height: 40px;
        text-transform: uppercase;
    }
}